import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap Modal
import { url } from "../index";
import { notification } from "antd";

const HistoryOfSurgery = () => {
  const [surgeries, setSurgeries] = useState([]);
  const [newSurgery, setNewSurgery] = useState("");
  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  // Fetch surgeries on component load
  useEffect(() => {
    fetchSurgeries();
  }, []);

  const fetchSurgeries = async () => {
    try {
      const response = await axios.get(`${url}/api/history-of-surgery`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      setSurgeries(response.data);
    } catch (err) {
      console.error("Error fetching surgeries:", err);
    }
  };

  const handleAddSurgery = async () => {
    try {
      await axios.post(
        `${url}/api/history-of-surgery/`,
        { surgery: newSurgery },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        }
      );
      setNewSurgery("");
      fetchSurgeries(); // Re-fetch surgeries after adding
    } catch (error) {
      if (error.response?.status === 409) {
        // Handle duplicate value error
        notification.error({
          message: "Duplicate Surgery History",
          description: "A Surgery History with this value already exists.",
        });
      } else {
        notification.error({
          message: "Error Saving Surgery History",
          description: error.response?.data?.error || "An unexpected error occurred.",
        });
      }
    }
  };

  const handleUpdateSurgery = async () => {
    try {
      await axios.put(
        `${url}/api/history-of-surgery/${selectedSurgery.id}`,
        { surgery: selectedSurgery.surgery },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("med_token")}`,
          },
        }
      );
      setSelectedSurgery(null);
      fetchSurgeries(); // Re-fetch surgeries after updating
    } catch (error) {
      if (error.response?.status === 409) {
        // Handle duplicate value error
        notification.error({
          message: "Duplicate Surgery History",
          description: "A Surgery History with this value already exists.",
        });
      } else {
        notification.error({
          message: "Error Saving Surgery History",
          description: error.response?.data?.error || "An unexpected error occurred.",
        });
      }
    }
  };

  const handleDeleteSurgery = async (id) => {
    try {
      await axios.delete(`${url}/api/history-of-surgery/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      fetchSurgeries(); // Re-fetch surgeries after deleting
    } catch (err) {
      console.error("Error deleting surgery:", err);
    }
  };

  return (
    <div className="w-full">
      <h1 className="text-center text-3xl text-gray-700 font-bold mb-4">History of Surgery</h1>

      {/* Form to Add New Surgery */}
      <div className="mb-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={newSurgery}
            onChange={(e) => setNewSurgery(e.target.value)}
            placeholder="Enter new surgery"
          />
          <button className="btn btn-primary" onClick={handleAddSurgery}>
            Add Surgery
          </button>
        </div>
      </div>

      {/* List of Surgeries */}
      <ul className="list-group">
        {surgeries.map((surgery) => (
          <li key={surgery.ID} className="list-group-item d-flex justify-content-between align-items-center">
            {surgery.surgery}
            <div>
              <button className="btn btn-warning btn-sm me-2" onClick={() => setSelectedSurgery(surgery)}>
                Edit
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => handleDeleteSurgery(surgery.id)}>
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Edit Surgery Form */}
      {selectedSurgery && (
        <div className="mt-4">
          <h4 className="text-lg font-semibold text-gray-800 mb-3">Edit Surgery</h4>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={selectedSurgery.surgery}
              onChange={(e) => setSelectedSurgery({ ...selectedSurgery, surgery: e.target.value })}
            />
            <button className="btn btn-success" onClick={handleUpdateSurgery}>
              Update Surgery
            </button>
          </div>
        </div>
      )}

      {/* Modal Popup */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>The surgery you are trying to add already exists.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HistoryOfSurgery;
