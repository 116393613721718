import React, { useState } from 'react';
import axios from 'axios';
import { Table, AutoComplete, message } from 'antd'; // Added message for error handling
import { url } from '../index'; // Assuming your base API URL is exported from here
import './MedicineSearch.css'; // Assuming the CSS file is named MedicineSearch.css

const MedicineSearch = () => {
    const [medicineName, setMedicineName] = useState(''); // Search input value
    const [searchResults, setSearchResults] = useState([]); // To store search results
    const [options, setOptions] = useState([]); // For AutoComplete options
    const [loading, setLoading] = useState(false); // Loader

    // Define the token here so it is accessible in both functions
    const token = 'd61dea79-2258-4298-b553-5747ed255f08'; // Static token

    // Function to handle search with token authorization
    const handleSearch = async (value) => {
        // if (!token) {
        //     message.error('Token is missing. Please provide a valid token.');
        //     return;
        // }

        if (!value.trim()) {
            setOptions([]); // Clear options if input is empty
            setSearchResults([]); // Clear results if input is empty
            return;
        }

        setLoading(true);

        try {
            const response = await axios.get(`${url}/api/medicine-search`, {
                params: { MedicineName: value },
                // headers: {
                //     Authorization: `Bearer ${token}`, // Add token in headers for secure API access
                // },
            });

            console.log(response.data, "medicine search response");

            // Set options for AutoComplete (limit to 7 results)
            const autoCompleteOptions = response.data.slice(0, 7).map((medicine) => ({
                value: medicine.MedicineName,
                label: `${medicine.MedicineName} - ${medicine.FullComposition || 'N/A'}`, // Display both name and composition
            }));

            setOptions(autoCompleteOptions); // Update autocomplete options
        } catch (error) {
            console.error('Error searching medicines:', error);
            message.error('Error fetching medicines. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to update the list when a medicine is selected from AutoComplete
    const handleSelect = async (value) => {
        setLoading(true);
        setMedicineName(value); // Update selected medicine in state

        try {
            const response = await axios.get(`${url}/api/medicine-search`, {
                params: { MedicineName: value.split(' - ')[0] }, // Use the medicine name (before the composition)
                headers: {
                    Authorization: `Bearer ${token}`, // Add token in headers for secure API access
                },
            });

            setSearchResults(response.data); // Update the table with selected medicine data
        } catch (error) {
            console.error('Error fetching selected medicine:', error);
            message.error('Error fetching selected medicine details.');
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Medicine Name',
            dataIndex: 'MedicineName',
            key: 'MedicineName',
        },
        {
            title: 'Full Composition',
            dataIndex: 'FullComposition',
            key: 'FullComposition',
            render: (text) => text || 'N/A', // Display 'N/A' if no composition is available
        },
        {
            title: 'Dosage & Time',
            dataIndex: 'DosagePatternWithTime',
            key: 'DosagePatternWithTime',
        },
        {
            title: 'Frequency',
            dataIndex: 'FrequencyName',
            key: 'FrequencyName',
        },
        {
            title: 'Number of Days',
            dataIndex: 'NumberOfDays',
            key: 'NumberOfDays',
        },
    ];

    return (
        <div>
            <h2>Search Medicines</h2>
            <AutoComplete
                value={medicineName}
                options={options} // Options for AutoComplete
                onSearch={handleSearch} // Call search function as user types
                onSelect={handleSelect} // Trigger the search with selected medicine
                onChange={(value) => setMedicineName(value)} // Handle input change
                placeholder="Enter Medicine Name or Composition"
                style={{ width: '400px', marginBottom: '20px' }} // Increased width for better readability
                allowClear
            />
            
            {/* Display search results in the table */}
            <Table
                columns={columns}
                dataSource={searchResults}
                rowKey="MedicineName"
                loading={loading}
                pagination={false} // Disable pagination for autocomplete results
            />
        </div>
    );
};

export default MedicineSearch;
