import React, { useState, useEffect } from 'react';
import { url } from '..'; // Ensure you have the correct path for your base URL
import axios from 'axios';

const SymptomsList = () => {
  const [symptoms, setSymptoms] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSymptom, setCurrentSymptom] = useState({ Id: null, Symptoms: '' });
  const [editing, setEditing] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 10;

  // Fetch symptoms with pagination and search
  const getSymptoms = async (search = '', page = 1, limit=10) => {
    try {
      const response = await axios.get(`${url}/api/symptoms?page=${page}&limit=${limit}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      const { data, total } = response.data;
      setSymptoms(data);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching symptoms:', error);
    }
  };

  // Fetch symptoms when component mounts or when search or page changes
  useEffect(() => {
    getSymptoms(searchTerm, page);
  }, [searchTerm, page]);

  // Add new symptom
  const addSymptom = async (text) => {
    try {
      const response = await axios.post(`${url}/api/symptoms`, { symptoms: text }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      if (response.status === 201) {
        setCurrentSymptom({ id: null, symptoms: '' }); // Clear input after adding
        getSymptoms(searchTerm, page); // Fetch updated list of symptoms
      }
    } catch (error) {
      console.error('Error adding symptom:', error);
    }
  };

  // Save edited symptom
  const saveSymptom = async (id, newText) => {
    try {
      const response = await axios.put(`${url}/api/symptoms/${id}`, { symptoms: newText }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      if (response.status === 200) {
        setEditing(false);
        setCurrentSymptom({ id: null, text: '' });
        getSymptoms(searchTerm, page); // Fetch updated list after editing
      }
    } catch (error) {
      console.error('Error updating symptom:', error);
    }
  };

  // Delete symptom
  const deleteSymptom = async (id) => {
    try {
      const response = await axios.delete(`${url}/api/symptoms/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("med_token")}`,
        },
      });
      if (response.status === 204) {
        getSymptoms(searchTerm, page); // Fetch updated list after deleting
      }
    } catch (error) {
      console.error('Error deleting symptom:', error);
    }
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle search
  const filteredSymptoms = symptoms.filter((symptom) =>
    symptom?.Symptoms?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Manage Symptoms</h2>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search symptoms..."
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder={editing ? 'Edit symptom...' : 'Add new symptom...'}
          value={currentSymptom.Symptoms}
          onChange={(e) => setCurrentSymptom({ ...currentSymptom, symptoms: e.target.value })}
        />
      </div>

      <div className="mb-4">
        {editing ? (
          <button
            className="btn btn-primary"
            onClick={() => saveSymptom(currentSymptom.Id, currentSymptom.Symptoms)}
            disabled={!currentSymptom.Symptoms}
          >
            Save Symptom
          </button>
        ) : (
          <button
            className="btn btn-success"
            onClick={() => addSymptom(currentSymptom.Symptoms)}
            disabled={!currentSymptom.Symptoms}
          >
            Add Symptom
          </button>
        )}
      </div>

      <ul className="list-group">
        {filteredSymptoms.length > 0 ? (
          filteredSymptoms.map((symptom) => (
            <li key={symptom.Id} className="list-group-item d-flex justify-content-between">
              {symptom.Symptoms}
              <div>
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => {
                    setEditing(true);
                    setCurrentSymptom(symptom);
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteSymptom(symptom.Id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))
        ) : (
          <li className="list-group-item">No symptoms found.</li>
        )}
      </ul>

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>Page {page}</span>
        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page + 1)}
          disabled={page * limit >= total}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SymptomsList;
